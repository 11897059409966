/**
 * Display messages count for postbox.
 * Placed here because needed on all pages + needs to be dynamic on message read (via vue.js).
 */
import {createApp} from 'vue';

window.module_top_nav = createApp({
    data: function () {
        return window.vue_model_data;
    },
}).mount('#topnav-loggedin');



/**
 * Adjust the shopping cart count in topnav.
 */
function topnav_update_cart(cart_items_count) {

    var cart_num = $('.mainheader .shopping .num');
    if (cart_num.length > 0) {

        /* zero -> remove number */
        if (cart_items_count == 0) {
            cart_num.remove();
        }

        /* Existing -> just update */
        else {
            cart_num.html(cart_items_count);
        }
    }
    /* No num block available -> create */
    else {
        $('<span class="num round">'+cart_items_count+'</span>').appendTo('.mainheader .shopping .glyphicon-shopping-cart');
    }
}
